import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";

const Player = {
  createdAt: String,
  id: String,
  name: String,
} satisfies ModelDefinition;

export const PlayerValidator = createModel(Player);

export type Player = DeepReadonly<FromModel<typeof PlayerValidator>>;
