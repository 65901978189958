import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";

const ProfileStats = {
  wins: Number,
  losses: Number,
  games_played: Number,
  kills: Number,
  deaths: Number,
  assists: Number,
  denies: Number,
  last_hits: Number,
  net_worth: Number,
  time_played: Number,
  player_damage: Number,
  player_healing: Number,
  shots_missed: Number,
  shots_hit: Number,
  hero_bullets_hit_crit: Number,
  first_match_id_processed: Number,
  last_match_id_processed: Number,
} satisfies ModelDefinition;

export const ProfileStatsValidator = createModel(ProfileStats);

export type ProfileStats = DeepReadonly<
  FromModel<typeof ProfileStatsValidator>
>;
