import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-deadlock/api.mjs";
import type { Player } from "@/game-deadlock/models/player.mjs";
import { PlayerValidator } from "@/game-deadlock/models/player.mjs";
import { devWarn } from "@/util/dev.mjs";

// This query doesn't exist in the BE yet
const isDisabled = true;

export default function getPlayer(props: {
  steamId: string;
}): Promise<Player | undefined> {
  if (!props?.steamId || isDisabled) return;
  try {
    // @ts-ignore
    return getData(API.getPlayer(props), PlayerValidator, [
      "deadlock",
      "profiles",
      props.steamId,
    ]);
  } catch (e) {
    devWarn(
      `Deadlock: Failed to fetch player with steam id: ${props.steamId}`,
      e,
    );
  }
}
