import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  isPersistent,
  readState,
} from "@/__main__/app-state.mjs";
import type { MatchlistMeta } from "@/game-deadlock/models/matchlist.mjs";
import { shallowEqual } from "@/util/deep-equal.mjs";

export function setProfileByMatchlistMeta(
  matchlistMeta: MatchlistMeta | undefined,
): void {
  if (!matchlistMeta) return;
  const next = matchlistMeta.player;
  const accountId = next.account_id;
  if (!accountId) return;
  const prev = readState.deadlock.profiles[accountId];
  if (shallowEqual(prev, next)) return;
  const profile = { ...next, [isPersistent]: true };
  writeState.deadlock.profiles[accountId] = profile;
}
