import type { RouteState } from "@/__main__/router.mjs";
import {
  getMatchlist,
  getMatchlistMeta,
} from "@/game-deadlock/fetches/matchlist.mjs";
import getPlayer from "@/game-deadlock/fetches/player.mjs";
import getProfileStats from "@/game-deadlock/fetches/profile-stats.mjs";
import { setProfileByMatchlistMeta } from "@/game-deadlock/utils/actions.mjs";

export default async function fetchData(
  [steamId]: FixedLengthArray<string, 2>,
  searchParam: URLSearchParams,
  state: RouteState,
): Promise<void> {
  await Promise.all(
    (
      Object.entries(state.transient?.visibleMatches ?? {})
        .filter(([_, isVisible]) => isVisible)
        .map(([matchId]) =>
          getMatchlistMeta({
            steamId,
            matchId,
          }),
        ) as P
    ).concat([
      getPlayer({ steamId }),
      getProfileStats({ steamId }),
      // Todo: Remove this matchlist meta / setProfile implementation when we have getPlayer working
      (async () => {
        const matchlist = await getMatchlist({ steamId });
        const latestMatchId = matchlist?.[0];
        if (!latestMatchId) return;
        setProfileByMatchlistMeta(
          await getMatchlistMeta({ steamId, matchId: latestMatchId }),
        );
      })(),
    ] as P),
  );
}

type P = Array<Promise<unknown>>;
