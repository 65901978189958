import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Optional } from "@/__main__/data-model.mjs";
import { Match } from "@/game-deadlock/models/match.mjs";

const MatchlistMeta = {
  duration_s: Number,
  game_mode: Number,
  match_id: Number,
  match_outcome: Number,
  player: Match.players[0],
  heroes: Optional([Number]), // Todo: Remove Optional when BE adds this property
  winning_team: Number,
  start_time: Number,
} satisfies ModelDefinition;

export const MatchlistValidator = createModel([String]);
export const MatchlistMetaValidator = createModel(MatchlistMeta);

export type Matchlist = DeepReadonly<FromModel<typeof MatchlistValidator>>;
export type MatchlistMeta = DeepReadonly<
  FromModel<typeof MatchlistMetaValidator>
>;
